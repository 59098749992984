<template>
  <v-card min-width="300px" height="350px" flat class="rounded-lg">
    <v-card-title class="text-color-title caption font-weight-bold py-1">
      {{ title }}
      <v-spacer></v-spacer>
      <v-btn icon @click="menuClick()">
        <v-icon>mdi-menu-open</v-icon>
      </v-btn>
    </v-card-title>
    <v-divider />
    <v-card-text class="d-flex justify-space-around">
      <v-img
        :src="video.thumbnail"
        height="250"
        width="150"
        class="rounded-lg mr-4"
      />
      <div class="text-color-main text-left">
        <div class="text-h6 font-weight-black">
          {{ video.title }}
        </div>
        <div class="caption">
          {{ video.description }}
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  data: () => ({
    title: "FEATURED VIDEO"
  }),
  props: {
    featuredVideo: {
      type: Object,
      default: null
    }
  },
  computed: {
    video() {
      if (this.featuredVideo) {
        return {
          title: this.featuredVideo.title,
          description: this.featuredVideo.description,
          thumbnail: this.featuredVideo.vertical_thumbnail_url
        };
      }

      return {};
    }
  }
};
</script>
